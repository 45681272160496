import $ from 'jquery'

function actionsFormToggle() {

	// Application Form Show/Hide Sections
	let formRules = [
		{
			// Application Qualification Subjects Question
			hideClass: 'js-hide',
			hiddenTrigger: '[name=appQualificationsPredict]',
			hiddenElement: '.application-qualifications-subjects'
		},
		{
			// Application Qualification Actual Question
			hideClass: 'js-hide',
			hiddenTrigger: '[name=appQualificationsActual]',
			hiddenElement: '.application-qualifications-actual'
		},
		{
			// Application Employment Question
			hideClass: 'js-hide',
			hiddenTrigger: '[name=appEmploymentCurrent]',
			hiddenElement: '.application-employment'
		},
		{
			// Application Address EU Question
			hideClass: 'js-hide',
			hiddenTrigger: '[name=appResidenceUK]',
			hiddenElement: '.application-eu'
		},
		{
			// Application UK Address Question
			hideClass: 'js-hide',
			hiddenTrigger: '[name=appResidenceUKAddress]',
			hiddenElement: '.application-uk-address'
		},
		{
			// Application UK Study Question
			hideClass: 'js-hide',
			hiddenTrigger: '[name=appImmigrationUKstudied]',
			hiddenElement: '.application-uk-studied'
		},
		{
			// Application UK Study Question
			hideClass: 'js-hide',
			hiddenTrigger: '[name=appAccommodationArranged]',
			hiddenElement: '.application-accommodation'
		},
	]

	// Loop Through Form and Show/Hide
	formRules.forEach(function (item) {
		$(item.hiddenTrigger).change(function () {
			$(item.hiddenElement).toggleClass(item.hideClass)
		})
	})

}

export default actionsFormToggle
