import $ from 'jquery'

function actionsHeroList() {
	$('.m-hero__list > li').addClass('m-hero__list-child');
	$('.m-hero__list-child').hover(function () {
		$('.m-hero__list-child').removeClass('m-hero__title');
		$(this).addClass('m-hero__title');
  });
}

export default actionsHeroList
