import $ from 'jquery'

function actionsFormAge() {

	let $today = new Date()

	let $dobYearDifference = 0
	let $dobMonthDifference = 0
	let $dobTotalDifference = 0

	let $dobDayValue = null
	let $dobMonthValue = null
	let $dobYearValue = null

	let $dobDayToday = $today.getDate()
	let $dobMonthToday = $today.getMonth()
	let $dobYearToday = $today.getFullYear()

	let $dobDayInput = $('#appPersonalDobDay')
	let $dobMonthInput = $('#appPersonalDobMonth')
	let $dobYearInput = $('#appPersonalDobYear')
	let $dobRestrictedForm = $('.application-age')

	let $hideClass = 'js-hide'

	$dobDayInput.change(function () {
		$dobDayValue = $(this).val()
		dobDayAge()
	})

	$dobMonthInput.change(function () {
		$dobMonthValue = $(this).val()
		dobDayAge()
	})

	$dobYearInput.change(function () {
		$dobYearValue = $(this).val()
		dobDayAge()
	})

	function dobDayAge() {

		$dobYearDifference = $dobYearToday - $dobYearValue
		$dobMonthDifference = $dobMonthToday - $dobDayValue

		if ($dobMonthDifference < 0 || ($dobMonthDifference === 0 && $dobDayToday < $dobDayValue)) {
			$dobTotalDifference = $dobYearDifference - 1;
		}

		if ($dobTotalDifference >= 25) {
			$dobRestrictedForm.toggleClass($hideClass)
		} else {
			$dobRestrictedForm.toggleClass($hideClass)
		}

	}
}

export default actionsFormAge
