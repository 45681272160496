import $ from 'jquery'

function actionsNavigation() {

	//console.log('this is the actionsNavigation')

  try {
    let urlParam = new URLSearchParams(window.location.search)
	  let urlNav = '<div style="width:100%;display:flex;justify-content:space-between;position:fixed;bottom:0;left:0;padding:10px;background:#005cb9;color:#ffffff;z-index:999999;"><a href="/frontend/modules.html?nav=true">M1</a><a href="/frontend/modules-2.html?nav=true">M2</a><a href="/frontend/modules-3.html?nav=true">M3</a><a href="/frontend/modules-4.html?nav=true">M4</a><a href="/frontend/modules-5.html?nav=true">M5</a><a href="/frontend/modules-6.html?nav=true">M6</a><a href="/frontend/modules-7.html?nav=true">M7</a><a href="/frontend/modules-8.html?nav=true">M8</a><a href="/frontend/pages/search-results-careers.html?nav=true">Search Careers</a><a href="/frontend/pages/search-results-everything-else.html?nav=true">Search Everything Else</a><a href="/frontend/pages/search-results-no-results.html?nav=true">Search Results No Results</a><a href="/frontend/pages/course-leaflet.html?nav=true">Course Leaflet</a><a href="/frontend/pages/events-index.html?nav=true">Events Index</a><a href="/frontend/pages/facilities-landing.html?nav=true">Facilities Landing</a><a href="/frontend/pages/news-index.html?nav=true">News Index</a></div>'

    if(urlParam.has('nav')) {
      $('body').append(urlNav);
    }

    $('.icon-onclick').click(function () {
      $('.-dropdown').toggle();
    });
  } catch (err) {
    console.log(err)
  }
}

const body = document.body;
const triggerMenu = document.querySelector(".g-header");
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

triggerMenu.addEventListener("click", () => {
  body.classList.toggle("menu-open");
});

var scrollDebounce = -1,
  scrollThreshold = 50;
window.addEventListener("scroll", () => {

  if(scrollDebounce == -1){
    scrollDebounce = setTimeout(()=> {
      const currentScroll = window.pageYOffset;
      if (currentScroll < 0) {
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
        scrollDebounce = -1;
        return;
      }
      
      if (currentScroll > lastScroll + scrollThreshold && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (currentScroll < lastScroll - scrollThreshold && body.classList.contains(scrollDown)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
      scrollDebounce = -1;
    }, 300);
  }
});
export default actionsNavigation
