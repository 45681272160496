import createBehavior from '../functions/createBehavior';

const gallery = createBehavior(
  'gallery',
  {
    updateImage(e) {
      let btn = e.currentTarget;

      if (btn === undefined) {
        btn = e;
      }

      //preview image src
      let src = btn.getAttribute('data-img-src');
      //large image src
      let fullSrc = btn.getAttribute('data-img-srcFull');
      this.videoUrl = btn.getAttribute('data-video-url');
      let text = btn.getAttribute('data-text');

      // On poster button click (this.galleryPoster) 
      // iframe (galleryIframe) will have set attribute videoUrl + '?autoplay=1'
      // Hide poster button
      // If there is a video (if videoUrl is filled in) then hide this.fullImg 
      // else if there is nothing filled in for video attribute then hide iframe and poster button

      this.image.setAttribute('data-video-url', this.videoUrl);
      this.image.setAttribute('src', src);
      this.image.setAttribute('alt', text);
      if(text.length == 0){
        this.caption.setAttribute('style', 'display:none;');
      }else{
        this.caption.removeAttribute('style');

        this.caption.innerHTML = text;
      }
      // this.fullImage.setAttribute('src', fullSrc);
      this.fullImage.setAttribute('alt', text);


      //check if fullSRC has a value (full image)
      if (typeof fullSrc !== "undefined" && fullSrc.length > 0) {
        //check the path of "src" contains an image extension jpg/jpeg/png/gif
        if (this.hasImageExt(fullSrc)) {
          this.fullImage.setAttribute('src', fullSrc);
          this.galleryPoster.style.backgroundImage = 'url(' + fullSrc + ')';
        }
      } else if (typeof src !== "undefined" && src.length > 0) {
        //if fullSRC is empty, use preview media instead (src)
        //check the path of "src" contains an image extension jpg/jpeg/png/gif
        if (this.hasImageExt(src)) {
          this.fullImage.setAttribute('src', src);
          this.galleryPoster.style.backgroundImage = 'url(' + src + ')';
        }
      }

      //if the button selected as a VIDEO value, then show controls (poster button)
      if ((typeof this.videoUrl !== "undefined" && this.videoUrl.length > 0)) {
        //change iframe here
        this.fullImage.style.display = 'none';
        this.galleryPoster.style.display = 'block';
        this.videoImage.style.display = 'block';
        this.appendIframe = true;
      }
      else {
        //if no VIDEO value, then hide the poster image and show static image
        this.fullImage.style.display = 'block';
        this.galleryPoster.style.display = 'none';
        this.videoImage.style.display = 'none';
      }

      this.thumbs.forEach(item => {
        item.classList.remove(this.activeClass);
      });

      btn.classList.add(this.activeClass);
    },
    init() {
      this.activeClass = "active";
      this.thumbs = this.node.querySelectorAll('.m-gallery__btn');
      this.firstThumb = this.thumbs[0];
      this.main = this.node.querySelector('.m-gallery__image');
      this.caption = this.main.querySelector('.m-gallery__caption');
      this.expand = this.main.querySelector('.a-btn--full');
      this.close = this.main.querySelector('.modal-gallery--close');
      this.modalClose = this.main.querySelector('.m-modal--gallery');
      this.modalTest = this.main.querySelector('.modal-test');
      // this.modalBackground = this.main.querySelector('.m-modal--background');
      //preview media image
      this.image = this.getChild('image');
      //full modal image element
      this.fullImage = this.getChild('fullImage');
      this.videoImage = this.main.querySelector('.gallery-video--play');
      this.appendIframe = false;
      this.videoUrl = "";

      this.fullImage.style.display = 'block';


      //Iframe for video
      //this.galleryIframe = this.getChild('iframe');
      //iframe container
      this.modalIframeC = this.main.querySelector('.m-modal .iframe-container');
      //Button to trigger video iframe
      this.galleryPoster = this.getChild('poster');

      this.updateImage(this.firstThumb);

      this.thumbs.forEach(item => {
        item.addEventListener('click', event => {
          this.removeIframe();
          this.updateImage(event);
          event.stopPropagation();
        });
      });    

      this.expand.addEventListener('click', event => {
        // this.modalBackground.style.display = 'block';
        this.modalTest.classList.add("show");
        this.modalTest.style.display = 'block';
        if (typeof this.videoUrl !== "undefined" && this.videoUrl.length > 0) {
          //this.galleryPoster.style.display = 'block';
          this.addIframe(this.videoUrl + '?autoplay=1');
          //this.appendIframe = true;
        } else if (typeof this.videoUrl === "undefined" && this.videoUrl.length < 1) {
          this.galleryPoster.style.display = 'none';
          this.appendIframe = false;
        }
      });
      
      this.close.addEventListener('click', event => {
        // this.modalBackground.style.display = 'none';
        this.modalTest.classList.remove("show");
        this.modalTest.style.display = 'none';
        this.removeIframe();
      });

      this.galleryPoster.addEventListener('click', event => {
        if(this.appendIframe && this.videoUrl !== ""){
          this.addIframe(this.videoUrl + '?autoplay=1');
        }
      });

    },
    hasImageExt(path) {
      return path.indexOf('.gif') > -1 || path.indexOf('.jpg') > -1 || path.indexOf('.png') > -1 || path.indexOf('.jpeg') > -1
    },
    addIframe(videoUrl){
        //create new IFRAME element
        var galleryIframe = document.createElement('iframe');

        //add appropriate attributes (including autoplay query string)
        if (videoUrl == "") {
          videoUrl = btn.getAttribute('data-video-url');
        }

        galleryIframe.setAttribute('src', videoUrl);
        galleryIframe.setAttribute('allow', 'autoplay; encrypted-media; fullscreen');
        galleryIframe.setAttribute('scrolling', 'no');
        galleryIframe.setAttribute('frameborder', '0');
        galleryIframe.style.width = "100%";
        galleryIframe.style.height = "100%";
        galleryIframe.id = "modal-gallery-iframe";
        //append this item inside .m-modal .iframe-container
        
        if(this.modalIframeC.querySelector('iframe') == null){
          this.modalIframeC.appendChild(galleryIframe);
        }
        //this.galleryIframe.setAttribute('src', videoUrl + '?autoplay=1');


        //hide poster image
        this.galleryPoster.style.display = 'none';
    },
    removeIframe() {
      if (document.getElementById('modal-gallery-iframe') !== null) {
          var iframeEl = document.getElementById('modal-gallery-iframe');
          if(iframeEl !== null){
            this.modalIframeC.removeChild(iframeEl);
          }
      }
      this.appendIframe = false;
    },
    destroy() {
      this.removeIframe();
      this.thumbs.forEach(item => {
        item.classList.remove(this.activeClass);
        item.removeEventListener('click', event => {
          this.updateImage(event);
        })
      })
    },
  }
);

export default gallery;
